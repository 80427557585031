import classNames from 'classnames';
import styles from './Button.module.scss';

export interface ButtonProps {
	children: React.ReactNode;
	style?: 'default' | 'primary' | 'secondary' | 'icon' | 'noAnimation' | 'filter' | 'arrowNext' | 'arrowPrev';
	inheritsColor?: boolean;
	title?: string;
	className?: string;
	type?: 'button' | 'submit' | 'reset';
	disabled?: boolean;
	selected?: boolean;
	onClick?: () => void;
	ariaLabel?: string;
}

export const Button: React.FC<ButtonProps> = ({
	children,
	style = 'default',
	inheritsColor,
	title,
	className,
	type,
	disabled,
	selected,
	onClick,
	ariaLabel,
}) => (
	<button
		type={type || 'button'}
		title={title}
		className={classNames(
			styles.Button,
			styles[`Button___${style}`],
			{ [styles.Button___selected]: selected },
			{ [styles.Button___inheritsColor]: inheritsColor },
			className,
		)}
		disabled={disabled}
		aria-disabled={disabled}
		onClick={onClick}
		aria-label={ariaLabel}
	>
		{style !== 'arrowPrev' && style !== 'arrowNext' && (
			<div className={styles.Button_textContainer}>
				<span className={styles.Button_text}>{children}</span>
				<span className={classNames(styles.Button_text, styles.Button_text___animationText, className)}>{children}</span>
			</div>
		)}

		{(style === 'arrowPrev' || style === 'arrowNext') && (
			<span className={classNames(styles[`Button_arrowContainer___${style}`], className)}></span>
		)}
	</button>
);
