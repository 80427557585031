import ReactPlayer from 'react-player/lazy';
import styles from './Player.module.scss';
import classNames from 'classnames';
import { ReactElement } from 'react';

export interface PlayerProps {
	url: string;
	isLight?: boolean;
	poster?: string;
	playing?: boolean;
	loop?: boolean;
	controls?: boolean;
	cover?: boolean;
	muted?: boolean;
	playIcon?: ReactElement;
}

export const Player: React.FC<PlayerProps> = ({
	url,
	muted = false,
	isLight = true,
	poster,
	playing = false,
	loop = false,
	controls = true,
	cover,
	playIcon,
}) => {
	const light = !isLight ? false : poster ? poster : true;

	return (
		<ReactPlayer
			url={url}
			className={classNames(styles.Player, { [styles['Player--cover']]: cover })}
			light={light}
			muted={muted}
			playing={playing}
			loop={loop}
			controls={controls}
			width="100%"
			height="100%"
			playsinline={true}
			playIcon={playIcon}
			config={{
				youtube: {
					playerVars: { modestbranding: 1 },
				},
				file: {
					attributes: {
						preload: 'auto',
					},
				},
			}}
		/>
	);
};
