import classNames from 'classnames';
import styles from './HeroCard.module.scss';
import { Heading } from 'ui/components/1-atoms/Heading/Heading';
import { Picture } from 'ui/components/1-atoms/Media/Picture/Picture';
import { Container } from 'ui/components/4-habitats/Container/Container';
import ColorOptions, { LinkType } from 'helpers/types';
import { RichText } from 'ui/components/1-atoms/RichText/RichText';
import { Kicker, KickerProps } from 'ui/components/1-atoms/Kicker/Kicker';
import { TopLevelGrid } from 'ui/components/4-habitats/TopLevelGrid/TopLevelGrid';
import { TopLevelGridCell } from 'ui/components/4-habitats/TopLevelGrid/TopLevelGridCell';
import { useEffect, useState } from 'react';
import { Player } from 'ui/components/1-atoms/Media/Player/Player';
import TextSplit from 'helpers/textSplit';

export interface HeroCardProps {
	className?: string;
	link?: LinkType;
	kicker?: KickerProps;
	kickerLeft?: boolean;
	heading?: string;
	bgColor?: ColorOptions;
	text?: string;
	sideText?: string;
	readMoreButtonText?: string;
	readMoreText?: string;
	readMoreButtonSideText?: string;
	readMoreSideText?: string;
	image?: {
		url: string;
		altText: string;
		focalPoint?: { left: number; top: number };
	};
	video?: {
		url: string;
	};
	noOverlay?: boolean;
	imageCropping?: 'cover' | 'contain';
}

const renderImageOrVideo = (video, image, imageCropping, noOverlay) => {
	return (
		<div className={styles.HeroCard_media}>
			<span className={styles.HeroCard_mediabox}>
				{video?.url ? (
					<Player url={video.url} isLight={false} playing={true} muted={true} loop={true} controls={false} cover={true} />
				) : (
					<Picture
						aspectRatio={0.5625}
						url={image.url}
						altText={image.altText}
						focalPoint={image.focalPoint}
						sizes={noOverlay ? '(max-width: 768px) 100vw, 1628px' : '100vw'}
						imageCropping={imageCropping}
						lazyload={false}
						eagerLoading={true}
						fetchpriority="high"
					/>
				)}
			</span>
		</div>
	);
};

export const HeroCard: React.FC<HeroCardProps> = ({
	heading,
	link,
	bgColor = 'white',
	kicker,
	text,
	className,
	image,
	imageCropping = 'cover',
	sideText,
	noOverlay,
	kickerLeft,
	video,
	readMoreButtonText,
	readMoreText,
	readMoreButtonSideText,
	readMoreSideText,
}) => {
	useEffect(() => {
		const textSplit = new TextSplit('.HeroCard_heading');

		return textSplit.onCleanup.bind(textSplit);
	}, [heading]);
	const kickerExists = kicker?.firstWord || kicker?.secondWord;

	const [scrollPosition, setScrollPosition] = useState(0);
	useEffect(() => {
		const handleScroll = () => {
			setScrollPosition(window.scrollY);
		};
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<header
			className={classNames(
				styles.HeroCard,
				styles[`u-color___${bgColor}`],
				{ [styles.HeroCard___hasBgImage]: image?.url != null },
				{ [styles.HeroCard___noOverlay]: noOverlay },
				{ [styles.scrolled]: scrollPosition > 0 && !noOverlay },
				className,
			)}
		>
			<div className={styles.HeroCard_container}>
				{image?.url && !noOverlay && renderImageOrVideo(video, image, imageCropping, noOverlay)}

				<div className={styles.HeroCard_textbox}>
					<Container width="Standard" fillWidth>
						<div className={styles.HeroCard_textboxInner}>
							{image == null ? (
								<TopLevelGrid container wrap>
									{kickerExists && kickerLeft && (
										<TopLevelGridCell desktopWidth="7col" mobileWidth="100">
											<Kicker {...kicker} className={styles.HeroCard_kicker} />
										</TopLevelGridCell>
									)}

									<TopLevelGridCell
										mobileWidth="100"
										desktopWidth={kickerExists && kickerLeft ? '17col' : '17col'}
										desktopOffset={!kickerExists && kickerLeft ? '7col' : '0col'}
										offsetDirection="left"
									>
										{kickerExists && !kickerLeft && <Kicker {...kicker} className={styles.HeroCard_kicker} />}

										<Heading headingLevel="h1" style="xxl" className={classNames(styles.HeroCard_heading, 'HeroCard_heading')}>
											<span className={styles.HeroCard_kickerInner}>{heading}</span>
										</Heading>
									</TopLevelGridCell>
								</TopLevelGrid>
							) : (
								<>
									{kickerExists && kickerLeft ? (
										<TopLevelGrid container wrap>
											<TopLevelGridCell desktopWidth="7col" mobileWidth="100">
												{<Kicker {...kicker} className={styles.HeroCard_kicker} />}
											</TopLevelGridCell>
											<TopLevelGridCell desktopWidth="17col" mobileWidth="100">
												<Heading
													headingLevel="h1"
													style="xxl"
													className={classNames(styles.HeroCard_heading, 'HeroCard_heading')}
												>
													<span className={styles.HeroCard_kickerInner}>{heading}</span>
												</Heading>
											</TopLevelGridCell>
										</TopLevelGrid>
									) : (
										<TopLevelGrid container wrap>
											<TopLevelGridCell
												mobileWidth="100"
												desktopWidth={kickerExists && kickerLeft ? '17col' : '17col'}
												desktopOffset={!kickerExists && kickerLeft ? '7col' : '0col'}
												offsetDirection="left"
											>
												{kickerExists && <Kicker {...kicker} className={styles.HeroCard_kicker} />}
												<Heading
													headingLevel="h1"
													style="xxl"
													className={classNames(styles.HeroCard_heading, 'HeroCard_heading')}
												>
													<span className={styles.HeroCard_kickerInner}>{heading}</span>
												</Heading>
											</TopLevelGridCell>
										</TopLevelGrid>
									)}
								</>
							)}
						</div>
					</Container>
				</div>
				{image?.url && noOverlay && <Container width="Standard">{renderImageOrVideo(video, image, imageCropping, noOverlay)}</Container>}
			</div>

			{text && (
				<Container width="Standard">
					<TopLevelGrid container wrap>
						{sideText ? (
							<>
								<TopLevelGridCell desktopWidth="7col" mobileWidth="100" className={styles.HeroCard_sideTextWrapper}>
									<div className={classNames(styles.HeroCard_sideText, !image && styles.HeroCard_sideText___noImg)}>
										<RichText content={sideText} readMoreButtonText={readMoreButtonSideText} readMoreText={readMoreSideText} />
									</div>
								</TopLevelGridCell>
								<TopLevelGridCell desktopWidth={'10col'} expand="17col" mobileWidth="100" className={styles.HeroCard_manchetWrapper}>
									<div className={classNames(styles.HeroCard_manchet, !image && styles.HeroCard_manchet___noImg)}>
										<RichText content={text} readMoreButtonText={readMoreButtonText} readMoreText={readMoreText} bigText />
									</div>
								</TopLevelGridCell>
							</>
						) : (
							<>
								<TopLevelGridCell desktopWidth="10col" desktopOffset={'7col'} center mobileWidth="100">
									<div className={classNames(styles.HeroCard_manchet, !image && styles.HeroCard_manchet___noImg)}>
										<RichText content={text} readMoreButtonText={readMoreButtonText} readMoreText={readMoreText} bigText />
									</div>
								</TopLevelGridCell>
							</>
						)}
					</TopLevelGrid>
				</Container>
			)}
		</header>
	);
};
