import config from './imageSizes';

interface GetImageUrlProps {
	url: string;
	focalPoint?: {
		left: number;
		top: number;
	};
	width?: number;
	height?: number;
	format?: 'webp';
}

export const GetImageUrl = ({ url, width, format }: GetImageUrlProps): string => {
	if (!url) return '';

	let imageUrl = `${url}?width=${width}`;

	if (format) imageUrl = `${imageUrl}&format=webp`;

	return imageUrl;
};

export const GetSrcSetString = ({
	url,
	focalPoint,
	format,
	aspectRatio,
}: {
	url: string;
	focalPoint: { top: number; left: number };
	format?: 'webp';
	aspectRatio?: number;
}): string => {
	const { imageSizes } = config.images;

	let string = '';

	imageSizes.map((width) => {
		let height = 0;

		if (aspectRatio) {
			height = aspectRatio * width;
		}

		return (string += `${GetImageUrl({
			url,
			focalPoint,
			height,
			width: width,
			format,
		})} ${width}w, `);
	});

	return string.substring(0, string.length - 2);
};
