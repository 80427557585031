import { throttle } from 'helpers/throttle';

export default class TextSplit {
	selector: string;
	elements: NodeListOf<Element>;
	origTextArray: string[] = [];
	mediaQueries = {
		mq320: 320,
		mq340: 340,
		mq360: 360,
		mq400: 400,
		mq480: 480,
		mq600: 600,
		mq720: 720,
		mq960: 960,
		mq1440: 1440,
	};
	storedMQ = 0;
	resizeListener: any;

	constructor(selector: string) {
		this.selector = selector;
		this.origTextArray = [];
		this.resizeListener = throttle(this.onWindowResize.bind(this), 200);
		if (document.readyState === 'complete') {
			this.onWindowLoad.bind(this)();
			window.addEventListener('resize', this.resizeListener);
		} else {
			window.addEventListener('load', this.onWindowLoad.bind(this));

			window.addEventListener('resize', this.resizeListener);
		}
	}

	onWindowLoad() {
		this.elements = document.querySelectorAll(this.selector);

		this.elements.forEach((el) => {
			this.splitTextContent(el);
		});
	}

	onWindowResize() {
		if (!this.hasMediaQueryChanged()) {
			return;
		}

		for (let i = 0; i < this.elements.length; i++) {
			const el = this.elements[i];
			el.innerHTML = '';
			const orig = this.origTextArray[i];
			el.textContent = orig;
			this.splitTextContent(el);
		}
	}

	onCleanup() {
		window.removeEventListener('resize', this.resizeListener);
	}

	splitTextContent(element: Element) {
		const originalText = element.textContent;
		element.textContent = '';

		this.origTextArray.push(originalText);

		const spanSections = [];

		originalText.split(' ').forEach((word) => {
			const newSpan = document.createElement('span');
			newSpan.textContent = word + ' ';

			element.appendChild(newSpan);

			let index = 0;
			if (newSpan.scrollTop < 0) {
				index = newSpan.offsetTop - newSpan.scrollTop;
			}

			if (spanSections[index] === undefined) {
				spanSections[index] = '';
			}

			spanSections[index] += newSpan.textContent;
		});

		element.textContent = '';

		for (let i = 0; i < spanSections.length; i++) {
			if (spanSections[i] !== undefined) {
				const spanText = spanSections[i].trim();
				const newNewSpan = document.createElement('span');
				newNewSpan.textContent = spanText;
				element.appendChild(newNewSpan);
			}
		}

		const injectedSpans = element.getElementsByTagName('span');
		[...injectedSpans].forEach((el) => {
			el.classList.add('split-text');
		});
	}

	hasMediaQueryChanged() {
		const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		let currentMq = 0;

		if (currentMq == 0) currentMq = width;

		for (const mq in this.mediaQueries) {
			if (width >= this.mediaQueries[mq]) {
				currentMq = this.mediaQueries[mq];
			}
		}

		if (currentMq != this.storedMQ) {
			this.storedMQ = currentMq;
			return true;
		}
		return false;
	}
}
