import classNames from 'classnames';
import { useState, useEffect, useRef } from 'react';
import styles from './Picture.module.scss';
import { GetImageUrl, GetSrcSetString } from 'helpers/getImageUrl';
import config from 'helpers/imageSizes';
import { inView } from 'helpers/inView';

export interface PictureProps {
	url: string;
	altText: string;
	focalPoint?: { left: number; top: number };
	sizes?: string;
	lazyload?: boolean;
	eagerLoading?: boolean;
	objectFitFallback?: boolean;
	aspectRatio?: number;
	className?: string;
	backgroundColor?: string;
	imageCropping?: 'cover' | 'contain';
	fileType?: string;
	mobileNoShow?: boolean;
	desktopNoShow?: boolean;
	roundBorder?: boolean;
	fetchpriority?: 'high' | 'low' | 'auto';
}

export const Picture: React.FC<PictureProps> = ({
	sizes = '100vw',
	url,
	altText,
	lazyload = true,
	eagerLoading = false,
	objectFitFallback = true,
	focalPoint,
	aspectRatio,
	className,
	backgroundColor,
	imageCropping = 'cover',
	fileType,
	mobileNoShow,
	desktopNoShow,
	roundBorder,
	fetchpriority,
}) => {
	const { thumbnailSize, fallbackSize } = config.images;

	const thumbnailImageUrl = GetImageUrl({ url, width: thumbnailSize });
	const thumbnailImageUrlWebp = GetImageUrl({ url, width: thumbnailSize, format: 'webp' });

	const [src, setSrc] = useState(thumbnailImageUrl);
	const [imgSrcSet, setImgSrcSet] = useState(thumbnailImageUrl);
	const [sourceSrcSet, setSourceSrcSet] = useState(thumbnailImageUrlWebp);
	const [isFallback, setIsFallback] = useState(false);
	const focalPointToCSS = focalPoint?.left * 100 + '% ' + focalPoint?.top * 100 + '%';

	const pictureRef = useRef(null);

	useEffect(() => {
		if (objectFitFallback) setObjectFitFallback();
		if (lazyload) inView({ elm: pictureRef.current, callback: reveal });
		if (!lazyload) reveal();
	});

	const reveal = () => {
		const srcSetString = GetSrcSetString({ url, focalPoint, aspectRatio });
		const srcSetStringWebp = GetSrcSetString({ url, focalPoint, format: 'webp', aspectRatio });
		const srcString = GetImageUrl({ url, width: fallbackSize });

		setImgSrcSet(srcSetString);
		setSourceSrcSet(srcSetStringWebp);
		setSrc(srcString);
	};

	const setObjectFitFallback = () => {
		// If the useragent doesn't support ObjectFit (IE), we need to
		// add fallback image to the picture element
		const supportObjectFit = 'objectFit' in document.documentElement.style;

		if (!supportObjectFit) setIsFallback(true);
	};

	return (
		<picture
			ref={pictureRef}
			className={classNames(
				styles.Picture,
				{ [styles.isFallback]: isFallback },
				{ [styles.Picture___contain]: imageCropping === 'contain' },
				{ [styles.Picture___cover]: imageCropping === 'cover' },
				{ [styles.Picture___mobileNoShow]: mobileNoShow === true },
				{ [styles.Picture___desktopNoShow]: desktopNoShow === true },
				className,
			)}
			style={{ backgroundImage: isFallback ? `url(${src})` : 'none', backgroundColor: backgroundColor ? backgroundColor : 'transparent' }}
		>
			{!isFallback && (
				<>
					{fileType !== 'svg' && <source sizes={sizes} type="image/webp" srcSet={sourceSrcSet} />}
					<img
						src={url}
						alt={altText ? altText : ''}
						className={classNames(
							styles.Picture_asset,
							fileType === 'svg' && styles.Picture_asset___isSvg,
							roundBorder && styles.Picture_asset___roundBorder,
						)}
						srcSet={fileType !== 'svg' ? imgSrcSet : ''}
						sizes={sizes}
						style={{ objectPosition: imageCropping === 'cover' ? focalPointToCSS : undefined }}
						loading={lazyload ? 'lazy' : eagerLoading ? 'eager' : undefined}
						fetchpriority={fetchpriority}
					/>
				</>
			)}
		</picture>
	);
};
