import { useState } from 'react';
import styles from './RichText.module.scss';
import cx from 'classnames';
import { Button } from '../Action/Button/Button';
export interface RichTextProps {
	content: string;
	className?: string;
	bigText?: boolean;
	readMoreText?: string;
	readMoreButtonText?: string;
}

export const RichText: React.FC<RichTextProps> = ({ content, className, bigText, readMoreText, readMoreButtonText }) => {
	const [readMoreToggled, setReadMoreToggled] = useState(false);
	return (
		<div className={cx(className)}>
			<div className={cx(styles.RichText, { [styles.RichText___bigText]: bigText })} dangerouslySetInnerHTML={{ __html: content }} />
			{readMoreText && !readMoreToggled && <Button inheritsColor={true} style={'primary'} onClick={() => setReadMoreToggled(true)}>{readMoreButtonText}</Button>}
			{readMoreText && readMoreToggled && <div className={cx(styles.RichText, { [styles.RichText___bigText]: bigText })}  dangerouslySetInnerHTML={{ __html: readMoreText }} />}
		</div>
	);
};
